import styled from "styled-components";
import {Colors} from "../../style/ColorUtils";
import {XS_MAX_SIZE} from "../../util/ViewSizeUtils";

export const StyledLanguageSelector = styled.div`{
  position: absolute;
  top: calc(100vh - 85px);
  left: 30px;
  z-index: 30;
  
  @media only screen and (max-width: ${XS_MAX_SIZE}px) {
    left: 12px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
  }

  li {
    float: left;
  }

  button {
    padding: 0;
    background-color: unset;
    border: none;
    color: ${Colors.WHITE};
    text-align: center;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
  }

  img {
    width: 25px;
    filter: drop-shadow(0 0 6px rgb(255 255 255 / 0.4));
    padding: 8px;
  }

  img:hover {
    width: 31px;
    padding: 6px 5px 4px;
  }
}`
