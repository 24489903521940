import styled from "styled-components";
import {Colors} from "../../common/style/ColorUtils";

export const StyledPageNotFound = styled.div` {
  height: calc(100vh - 118px);

  color: ${Colors.WHITE};
  @media only screen and (max-width: 487px) {
    height: calc(100vh - 73px);
  }

  .page-not-found-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .number {
    font-size: 190px;
    text-align: center;
  }

  .page-not-found {
    font-size: 34px;
    text-align: center;
  }
}`
