import React, {FC, PropsWithChildren} from "react"
import {SiteMetaData} from "./service/SiteMetaData.type";
import {getSiteMetadata} from "./service/SiteMetaData.service";
import {isDefined} from "../../util/ObjectUtils";
import {getDefaultLanguage} from "../../../lang/TranslationUtils";

interface SeoProps {
    page?: string
    lang?: string
}

export const Seo: FC<SeoProps> = (props: PropsWithChildren<SeoProps>) => {
    const lang = isDefined(props.lang) ? props.lang : getDefaultLanguage();
    const siteMetaData: SiteMetaData = getSiteMetadata(props.page, lang);
    return (
        <>
            <title>{siteMetaData.title}</title>
            <meta name="description" content={siteMetaData.description}/>
            <meta name="title" content={siteMetaData.title}/>
            {props.children}
        </>
    )
}

Seo.defaultProps = {
    page: "index"
}
