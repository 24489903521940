import * as React from "react"
import {Translate} from "../../lang/Translate.component";
import {StyledPageNotFound} from "./PageNotFound.style";
import {LanguageSelector} from "../../common/components/languageSelector/LanguageSelector.component";

export const PageNotFoundComponent = () => {
    return <StyledPageNotFound>
        <div className={"page-not-found-container"}>
            <div className={"number"}>404</div>
            <div className={"page-not-found"}>
                <Translate translationKey={"PAGE_NOT_FOUND"}/>
            </div>
        </div>
        <LanguageSelector/>
    </StyledPageNotFound>
}
