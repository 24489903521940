import * as React from 'react'
import {FC} from 'react'
import {StyledLanguageSelector} from "./LanguageSelector.style";
import {changeLanguage, getAvailableLanguages} from "../../../lang/TranslationUtils";
import {Images} from "../../../images/Images";
import {ImageCustom} from "../image/ImageCustom.component";

export const LanguageSelector: FC = () => {
    return (
        <StyledLanguageSelector>
            <ul>
                {getAvailableLanguages().map(language =>
                    <li key={language}>
                        <button onClick={() => changeLanguage(language)}>
                            <ImageCustom className={"img-first-size"} image={Images.getFlag(language)}/>
                        </button>
                    </li>
                )}
            </ul>
        </StyledLanguageSelector>
    )
}
