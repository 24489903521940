import {graphql, useStaticQuery} from "gatsby"
import {getDefaultLanguage} from "../../../../lang/TranslationUtils";
import {SiteMetaData} from "./SiteMetaData.type";
import {isDefined} from "../../../util/ObjectUtils";

export const getSiteMetadata = (page: string = "index", language: string = getDefaultLanguage()): SiteMetaData => {
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          pl {
            index {
              title
              description
            },
            artists {
              title
              description
            },
            bookings {
              title
              description
            },
            pricing {
              title
              description
            }
          }
          en {
            index {
              title
              description
            },
            artists {
              title
              description
            },
            bookings {
              title
              description
            },
            pricing {
              title
              description
            }
          }
        }
      }
    }
  `)
    let siteMetadataLanguage = isDefined(data.site.siteMetadata[language])
        ? data.site.siteMetadata[language]
        : data.site.siteMetadata[getDefaultLanguage()];
    return siteMetadataLanguage[page] ? siteMetadataLanguage[page] : siteMetadataLanguage["index"];
}
